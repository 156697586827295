<template>
  <section id="dashboard-report">
    <b-row class="match-height">
      <!-- Settings Table Card -->
      <b-col>
        <report-table :table-data="data" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import ReportTable from './ReportTable.vue'

export default {
  components: {
    BRow,
    BCol,
    ReportTable,
  },
  data() {
    return {
      data: [],
    }
  },
  // created() {
  //   this.$http.post('/crawler/list')
  //     .then(response => {
  //       this.data = response.data
  //     })
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
