<template>
  <section id="crawler-settings-table">

    <!-- Add Modal -->
    <b-modal
      id="modal-add"
      title="Add New"
    >
      <b-container fluid>
        <b-form>
          <b-form-group
            label="Keywords"
            label-for="vue-select"
          >
            <v-select
              id="vue-select-keywords"
              v-model="selectedKeywords"
              taggable
              multiple
              @input="addKeywords"
            />
          </b-form-group>
          <b-form-group
            label="Status"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedStatus"
              :options="optionStatus"
            />
          </b-form-group>
          <b-form-group
            label="Type"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedType"
              :options="optionType"
            />
          </b-form-group>
          <b-form-group
            label="Language"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedLanguage"
              :options="optionLanguage"
              style="margin-bottom: 70px;"
            />
          </b-form-group>
        </b-form>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            :disabled="selectedKeywords==null || selectedKeywords=='' || selectedStatus==null || selectedType==null || selectedLanguage==null"
            style="margin-left:10px"
            @click="onSubmitAdd"
          >
            Save
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal-add')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="modal-edit"
      title="Edit"
    >
      <b-container fluid>
        <b-form>
          <b-form-group
            label="sss"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="editSelectedKeywords"
              taggable
              multiple
              @input="editKeywords"
            />
          </b-form-group>
          <b-form-group
            label="Status"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="editStatus"
              :options="optionStatus"
            />
          </b-form-group>
          <b-form-group
            label="Language"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="editLanguage"
              :options="optionLanguage"
              style="margin-bottom: 70px;"
            />
          </b-form-group>
        </b-form>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            :disabled="editSelectedKeywords==null || editSelectedKeywords=='' || editStatus==null || editLanguage==null"
            style="margin-left:10px"
            @click="onSubmitEdit"
          >
            Save
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal-edit')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal
      id="modal-delete"
      title="Delete Crawler"
      ok-title="Delete"
      cancel-variant="outline-secondary"
      @ok="onSubmitDelete"
    >
      <p>Are you sure you want to delete cralwer?</p>
    </b-modal>

    <!-- Table - CrawlerSettings -->
    <b-card
      no-body
      class="crawler-settings-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Add New -->
          <b-col
            cols="12"
            md="6"
          >

            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.modal-add
                variant="outline-primary"
              >
                Add New
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refCrawlerSettingsTable"
        :items="fetchCrawlerSettings"
        responsive
        :fields="fields"
        class="mb-0"
        show-empty
        empty-text="No matching records found"
      >

        <!-- id -->
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <!-- keywords -->
        <template #cell(keywords)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bolder">
                <span
                  v-for="(val,key) of data.item.keywords"
                  :key="key"
                >
                  <span>{{ val }}</span>
                  <span v-if="key != Object.keys(data.item.keywords).length - 1">, </span>
                </span>
              </div>
            </div>
          </div>
        </template>

        <!-- type -->
        <template #cell(type)="data">
          <b-badge
            pill
            :style="`color:${resolveTypeVariant(data.item.type)}; background-color:white`"
            class="text-capitalize"
          >
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- user -->
        <template #cell(user)="data">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder mb-25">{{ data.item.username }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            :id="data.item.id"
            v-b-modal.modal-edit
            variant="outline-primary"
            style="cursor:pointer"
            @click="editId = data.item.id; editSelectedKeywords = data.item.keywords; editStatus=data.item.status;editLanguage=data.item.language"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-button>
          <b-button
            :id="data.item.id"
            v-b-modal.modal-delete
            variant="outline-primary"
            style="cursor:pointer; margin-left:10px"
            @click="deleteId = data.item.id;"
          >
            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">Delete</span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCrawler"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BTable, BBadge, BRow, BCol, BContainer,
  BButton, BModal, BForm, BFormGroup, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCrawlerSettings from './useCrawlerSettings'
import crawlerStoreModule from './crawlerStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    BForm,
    BContainer,
    BFormGroup,
    vSelect,
    BPagination,
  },
  setup() {
    const CRAWLER_APP_STORE_MODULE_NAME = 'app-crawler'

    // Register module
    if (!store.hasModule(CRAWLER_APP_STORE_MODULE_NAME)) store.registerModule(CRAWLER_APP_STORE_MODULE_NAME, crawlerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CRAWLER_APP_STORE_MODULE_NAME)) store.unregisterModule(CRAWLER_APP_STORE_MODULE_NAME)
    })

    const typeOptions = [
      { label: 'SEA', value: 'SEA' },
      { label: 'PLA', value: 'PLA' },
    ]
    const statusOptions = [
      { label: 'inactive', value: 'inactive' },
      { label: 'active', value: 'active' },
    ]

    const {
      test,
      fetchCrawlerSettings,
      perPage,
      totalCrawler,
      currentPage,
      refetchData,
      perPageOptions,
      dataMeta,
      refCrawlerSettingsTable,

      // UI
      resolveTypeVariant,
      resolveStatusVariant,
    } = useCrawlerSettings()

    return {
      test,
      fetchCrawlerSettings,
      perPage,
      totalCrawler,
      currentPage,
      perPageOptions,
      dataMeta,
      refCrawlerSettingsTable,
      refetchData,

      // UI
      resolveTypeVariant,
      resolveStatusVariant,

      typeOptions,
      statusOptions,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedKeywords: [],
      selectedStatus: 'active',
      optionStatus: ['active', 'inactive'],
      selectedType: null,
      optionType: ['SEA', 'PLA'],
      selectedLanguage: { label: 'Polish', value: 1 },
      optionLanguage: [
        { label: 'Polish', value: 1 },
        { label: 'Russian ', value: 2 },
        { label: 'Ukrainia', value: 3 },
        { label: 'Romanian', value: 4 },
      ],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'keywords', label: 'KEYWORDS' },
        { key: 'type', label: 'TYPE' },
        { key: 'status', label: 'STATUS' },
        { key: 'language', label: 'LANGUAGE' },
        { key: 'user', label: 'USER' },
        { key: 'actions', label: 'ACTIONS' },
      ],
      editId: 0,
      editSelectedKeywords: '0',
      editStatus: '0',
      editLanguage: '0',
      deleteId: 0,
    }
  },
  methods: {
    addKeywords(newKeyword) {
      this.selectedKeywords = []
      newKeyword.forEach(val => {
        const keyword = val.split(',')
        if (keyword.length > 0) {
          keyword.forEach(value => {
            if (!this.selectedKeywords.includes(value) && !this.selectedKeywords.includes(value.substring(1))) {
              if (value[0] === ' ') {
                this.selectedKeywords.push(value.substring(1))
              } else {
                this.selectedKeywords.push(value)
              }
            }
          })
        } else {
          this.selectedKeywords.push(val)
        }
      })
    },
    editKeywords(newKeyword) {
      this.editSelectedKeywords = []
      newKeyword.forEach(val => {
        const keyword = val.split(',')
        if (keyword.length > 0) {
          keyword.forEach(value => {
            if (!this.editSelectedKeywords.includes(value) && !this.editSelectedKeywords.includes(value.substring(1))) {
              if (value[0] === ' ') {
                this.editSelectedKeywords.push(value.substring(1))
              } else {
                this.editSelectedKeywords.push(value)
              }
            }
          })
        } else {
          this.editSelectedKeywords.push(val)
        }
      })
    },
    onSubmitAdd() {
      let keywords = JSON.stringify(this.selectedKeywords)
      keywords = keywords.replaceAll(/"|\[|\]/ig, '')
      const request = {
        keywords,
        status: this.selectedStatus,
        type: this.selectedType,
        lang: this.selectedLanguage.value,
      }
      store.dispatch('app-crawler/addCrawlerSettings', request)
        .then(() => {
          this.$refs.refCrawlerSettingsTable.refresh()
          this.selectedKeywords = null
          this.selectedStatus = 'active'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Kewords',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    onSubmitEdit() {
      let keywords = JSON.stringify(this.editSelectedKeywords)
      keywords = keywords.replaceAll(/"|\[|\]/ig, '')
      const request = {
        id: this.editId,
        keywords,
        status: this.editStatus,
        lang: this.editLanguage.value,
      }
      store.dispatch('app-crawler/updateCrawlerSettings', request)
        .then(() => {
          this.$refs.refCrawlerSettingsTable.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Crawler Settings',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    onSubmitDelete() {
      store.dispatch('app-crawler/deleteCrawlerSettings', this.deleteId)
        .then(() => {
          this.$refs.refCrawlerSettingsTable.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Kewords',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
