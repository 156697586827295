import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://admanager-api.aza.io/api/',
  // baseURL: 'http://ads_manager.localhost/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
