<template>
  <section id="dashboard-crawler-settings">
    <b-row class="match-height">
      <!-- Settings Table Card -->
      <b-col>
        <crawler-settings-table :table-data="data" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import CrawlerSettingsTable from './CrawlerSettingsTable.vue'

export default {
  components: {
    BRow,
    BCol,
    CrawlerSettingsTable,
  },
  data() {
    return {
      data: [],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
