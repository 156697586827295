export default [
  {
    path: '/dashboard/crawler-settings',
    name: 'dashboard-crawler-settings',
    component: () => import('@/views/dashboard/crawler-settings/CrawlerSettings.vue'),
  },
  {
    path: '/dashboard/crawler-result',
    name: 'dashboard-crawler-result',
    component: () => import('@/views/dashboard/crawler-result/CrawlerResult.vue'),
  },
  {
    path: '/dashboard/report',
    name: 'dashboard-report',
    component: () => import('@/views/dashboard/report/Report.vue'),
  },
  {
    path: '/dashboard/report-brand',
    name: 'dashboard-report-brand',
    component: () => import('@/views/dashboard/report-brand/ReportBrand.vue'),
  },
]
