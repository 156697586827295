export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '3',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Crawler Settings',
        route: 'dashboard-crawler-settings',
      },
      {
        title: 'Crawler Result',
        route: 'dashboard-crawler-result',
      },
      {
        title: 'Report',
        route: 'dashboard-report',
      },
      {
        title: 'Report Brand',
        route: 'dashboard-report-brand',
      },
    ],
  },
]
