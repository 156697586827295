import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCrawlerSettings() {
  // // Use toast
  const toast = useToast()

  const refCrawlerSettingsTable = ref(null)

  const perPage = ref(10)
  const totalCrawler = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const dataMeta = computed(() => {
    const localItemsCount = refCrawlerSettingsTable.value ? refCrawlerSettingsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCrawler.value,
    }
  })

  const refetchData = () => {
    refCrawlerSettingsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })
  const fetchCrawlerSettings = (ctx, callback) => {
    store
      .dispatch('app-crawler/fetchCrawlerSettings', {
        limit: perPage.value,
        offset: perPage.value * (currentPage.value - 1),
      })
      .then(response => {
        const crawlerSettings = response.data.data

        callback(crawlerSettings)
        totalCrawler.value = response.data.recordsTotal
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching crawler settings list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTypeVariant = status => {
    if (status === 'SEA') return 'teal'
    if (status === 'PLA') return 'green'
    return 'orange'
  }

  const resolveStatusVariant = status => {
    if (status === 'inactive') return 'danger'
    if (status === 'active') return 'success'
    return 'primary'
  }

  return {
    fetchCrawlerSettings,
    totalCrawler,
    perPage,
    currentPage,
    perPageOptions,
    dataMeta,
    refCrawlerSettingsTable,

    resolveTypeVariant,
    resolveStatusVariant,
  }
}
