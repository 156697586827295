<template>
  <section id="crawler-result-table">

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Type</label>
            <v-select
              v-model="typeFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="7"
            class="mb-md-0 mb-2"
          >
            <label>Keywords</label>
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="vue-select-keywords"
                v-model="searchQuery"
                :options="keywordsOptionsFilter"
                style="width: 100%"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="1"
            class="mb-md-0 mb-2"
          >
            <div
              class="d-flex align-items-end justify-content-center"
              style="height:100%"
            >
              <b-button
                variant="outline-primary"
                @click="searchResult"
              >
                <span class="mr-25 align-middle">Search</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="crawler-settings-table"
    >
      <b-card-header class="pb-50">
        <h2 style="text-transform: capitalize">
          {{ name }}
        </h2>
      </b-card-header>
      <b-card-body>
        <b-table
          :items="resultItems"
          responsive
          :fields="fields"
          class="mb-0 text-center"
          show-empty
          empty-text="No matching records found"
        >

          <!-- text -->
          <template #cell(text)="data">
            <span v-html="data.item.text"></span>
          </template>

          <!-- action -->
          <template #cell(action)="data">
            <a
              class="btn btn-block btn-outline-primary"
              :href="data.item.href"
              target="_blank"
            >
              Page
            </a>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BTable, BRow, BCol, BCardHeader, BCardBody,
  BCard, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    vSelect,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      fields: [
        { key: 'brand', label: 'BRAND' },
        { key: 'price', label: 'PRICE' },
        { key: 'text', label: 'TEXT' },
        { key: 'ACTION', label: 'ACTION' },
      ],
      typeFilter: '',
      typeOptions: [
        { label: 'SEA', value: 'SEA' },
        { label: 'PLA', value: 'PLA' },
      ],
      searchQuery: null,
      keywordsOptions: [],
      resultItems: [],
      name: '',
      // search: 0,
    }
  },
  computed: {
    keywordsOptionsFilter() {
      return this.keywordsOptions.filter(keywords => keywords.value === this.typeFilter)
    },
  },
  created() {
    axios
      .get('/crawler/getKeywords')
      .then(response => {
        const data1 = response.data
        this.keywordsOptions = data1.map(a => ({
          label: a.keywords, value: a.type,
        }))
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    searchResult() {
      if (this.searchQuery === '' || this.searchQuery === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Keywords',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        axios
          .post('/crawler/resultList', {
            type: this.typeFilter,
            search: this.searchQuery.label,
          })
          .then(response => {
            if (response.data) {
              this.name = response.data.name
              this.resultItems = response.data.result
              if (this.typeFilter === 'SEA') {
                const removeItem = []
                const words = this.searchQuery.label.split(' ')
                words.forEach(item => {
                  const newItem = this.replaceCharacter(item)
                  response.data.result.forEach((el, i) => {
                    if (!el.href.includes(`https://www.${item}`) && !el.href.includes(`https://www.${newItem}`)) {
                      let stringLower = item.toLowerCase()
                      let stringUpper = item.toUpperCase()
                      let stringUpperFrist = stringLower.charAt(0).toUpperCase() + stringLower.slice(1)
                      let text = el.text.replaceAll(stringLower, `<b> ${stringLower} </b>`)
                      text = text.replaceAll(stringUpper, `<b> ${stringUpper} </b>`)
                      text = text.replaceAll(stringUpperFrist, `<b> ${stringUpperFrist} </b>`)

                      stringLower = newItem.toLowerCase()
                      stringUpper = newItem.toUpperCase()
                      stringUpperFrist = stringLower.charAt(0).toUpperCase() + stringLower.slice(1)
                      text = text.replaceAll(stringLower, `<b> ${stringLower} </b>`)
                      text = text.replaceAll(stringUpper, `<b> ${stringUpper} </b>`)
                      text = text.replaceAll(stringUpperFrist, `<b> ${stringUpperFrist} </b>`)
                      this.resultItems[i].text = text
                    } else if (!removeItem.includes(el.href)) {
                      this.resultItems.splice(i, 1)
                    }
                  })
                })
              }
            } else {
              this.name = null
              this.resultItems = null
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    replaceCharacter(string) {
      const character = ['ą', 'ę', 'ć', 'ś', 'ż', 'ź', 'ó', 'ł']
      const characterNew = ['a', 'e', 'c', 's', 'z', 'z', 'o', 'l']
      let newString = string
      character.forEach((el, i) => {
        newString = newString.replaceAll(el, characterNew[i])
      })
      return newString
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
