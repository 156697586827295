export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Crawler Settings',
        route: 'dashboard-crawler-settings',
        icon: 'ActivityIcon',
      },
      {
        title: 'Crawler Result',
        route: 'dashboard-crawler-result',
        icon: 'ActivityIcon',
      },
      {
        title: 'Report',
        route: 'dashboard-report',
        icon: 'ActivityIcon',
      },
      {
        title: 'Report Brand',
        route: 'dashboard-report-brand',
        icon: 'ActivityIcon',
      },
    ],
  },
]
