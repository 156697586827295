<template>
  <section id="report-brand-table">

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Type</label>
            <v-select
              v-model="typeFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="mb-md-0 mb-2"
          >
            <label>Keywords</label>
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="vue-select-keywords"
                v-model="searchQuery"
                :options="keywordsOptionsFilter"
                style="width: 100%"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Date</label>
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="1"
            class="mb-md-0 mb-2"
          >
            <div
              class="d-flex align-items-end justify-content-center"
              style="height:100%"
            >
              <b-button
                variant="outline-primary"
                @click="searchResult"
              >
                <span class="mr-25 align-middle">Search</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="crawler-settings-table"
    >
      <b-card-body>
        <b-table
          v-b-modal.modal-info
          :items="resultItems"
          responsive
          :fields="fields"
          class="mb-0 text-center"
          show-empty
          hover
          empty-text="No matching records found"
        >
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BTable, BRow, BCol, BCardHeader, BCardBody,
  BCard, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import reportBrandStoreModule from './reportBrandStoreModule'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    vSelect,
    BButton,
    flatPickr,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      fields: [
        { key: 'brand', label: 'BRAND', sortable: true },
        { key: 'position', label: 'POSITION', sortable: true },
        { key: 'share', label: 'IMPRESSION SHARE', sortable: true },
        { key: 'share_relative', label: 'IMPRESSION SHARE RELATIVE', sortable: true },
        { key: 'price', label: 'PRICE', sortable: true },
        { key: 'count', label: 'COUNT', sortable: true },
      ],
      typeFilter: '',
      typeOptions: [
        { label: 'SEA', value: 'SEA' },
        { label: 'PLA', value: 'PLA' },
      ],
      searchQuery: null,
      keywordsOptions: [],
      resultItems: [],
      name: '',
      date: null,
      dateDefault: [new Date().fp_incr(-30), new Date()],
      modalTitle: '',
    }
  },
  setup() {
    /* eslint-disable global-require */
    const noImage = require('@/assets/images/elements/no-image.jpg')
    const REPORT_APP_STORE_MODULE_NAME = 'app-report-brand'

    // Register module
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) {
      store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportBrandStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
      }
    })
    return {
      noImage,
    }
  },
  computed: {
    keywordsOptionsFilter() {
      return this.keywordsOptions.filter(keywords => keywords.value === this.typeFilter)
    },
  },
  created() {
    store.dispatch('app-report-brand/getKeywords')
      .then(response => {
        const data1 = response.data
        this.keywordsOptions = data1.map(a => ({
          label: a.keywords, value: a.type,
        }))
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    searchResult() {
      if (this.searchQuery === '' || this.searchQuery === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Keywords',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (this.dateDefault === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Date',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        const dateArray = Array.isArray(this.dateDefault) ? this.dateDefault : [this.dateDefault.substring(0, 10), this.dateDefault.substring(14)]
        store.dispatch('app-report-brand/fetchReportByBrand', {
          type: this.typeFilter,
          search: this.searchQuery.label,
          date: dateArray,
        })
          .then(response => {
            if (response.data) {
              this.resultItems = []
              Object.keys(response.data.brands).forEach(key => {
                this.resultItems.push({
                  brand: key,
                  position: response.data.brands[key].position,
                  count: response.data.brands[key].count,
                  price: response.data.brands[key].price,
                  share: response.data.brands[key].share,
                  share_relative: response.data.brands[key].share_relative,
                })
              })
            } else {
              this.name = null
              this.resultItems = null
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
