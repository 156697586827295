import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCrawlerSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/crawler/settingsList', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCrawlerSettings(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crawler/addCrawlerSettings', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCrawlerSettings(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crawler/updateCrawlerSettings', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCrawlerSettings(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/crawler/deleteCrawlerSettings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
