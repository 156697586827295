<template>
  <section id="report-table">

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Type</label>
            <v-select
              v-model="typeFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="mb-md-0 mb-2"
          >
            <label>Keywords</label>
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="vue-select-keywords"
                v-model="searchQuery"
                :options="keywordsOptionsFilter"
                style="width: 100%"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Date</label>
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="1"
            class="mb-md-0 mb-2"
          >
            <div
              class="d-flex align-items-end justify-content-center"
              style="height:100%"
            >
              <b-button
                variant="outline-primary"
                @click="searchResult"
              >
                <span class="mr-25 align-middle">Search</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="crawler-settings-table"
    >
      <b-card-body>
        <b-table
          v-b-modal.modal-info
          :items="resultItems"
          responsive
          :fields="fields"
          class="mb-0 text-center"
          show-empty
          hover
          empty-text="No matching records found"
          @row-clicked="clickRow"
        >
          <!-- action -->
          <template #cell(action)="row">
            <a
              v-b-modal.modal-brand
              @click="clickMore(row.item.date)"
            >
              <feather-icon
                icon="InfoIcon"
                size="18"
              />
            </a>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <!-- More Brand Modal -->
    <b-modal
      id="modal-brand"
      :title="modalTitle"
      ok-title="OK"
      size="xl"
      cancel-variant="outline-secondary"
    >
      <b-table
        v-b-modal.modal-brand
        :items="reportBrandItems"
        responsive
        :fields="reportBrandFields"
        class="mb-0 text-center"
        show-empty
        empty-text="No matching records found"
      />
    </b-modal>
    <!-- Info Modal -->
    <b-modal
      v-if="showModal === 'modal-info'"
      id="modal-info"
      :title="modalTitle"
      ok-title="OK"
      size="xl"
      cancel-variant="outline-secondary"
    >
      <b-table
        v-b-modal.modal-info
        :items="reportInfoItems"
        responsive
        :fields="reportInfoFields"
        class="mb-0 text-center"
        show-empty
        empty-text="No matching records found"
      >
        <!-- action -->
        <template #cell(more)="row">
          <b-button
            size="sm"
            class="mr-2"
            @click="row.toggleDetails"
          >
            <feather-icon
              v-if="row.detailsShowing"
              icon="ChevronUpIcon"
              size="18"
            />
            <feather-icon
              v-else
              icon="ChevronDownIcon"
              size="18"
            />
          </b-button>

          <b-form-checkbox
            v-model="row.detailsShowing"
            class="hidden"
            @change="row.toggleDetails"
          />
        </template>
        <template #row-details="row">
          <b-card>
            <b-table
              v-b-modal.modal-info
              :items="row.item.more"
              responsive
              :fields="reportInfoMoreFields"
              class="mb-0 text-center"
              show-empty
              empty-text="No matching records found"
            >
              <!-- image -->
              <template #cell(image)="data">
                <b-img
                  v-if="data.item.image"
                  center
                  :src="data.item.image"
                  alt="image"
                />
                <b-img
                  v-else
                  center
                  :src="noImage"
                  alt="image"
                  style="max-width:150px;"
                />
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </b-modal>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BTable, BRow, BCol, BCardHeader, BCardBody,
  BCard, BButton, BImg, BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import reportStoreModule from './reportStoreModule'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BImg,
    vSelect,
    BButton,
    flatPickr,
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true,
        },
        { key: 'position', label: 'POSITION' },
        { key: 'share', label: 'IMPRESSION SHARE' },
        { key: 'price', label: 'PRICE' },
        { key: 'action', label: '' },
      ],
      reportInfoFields: [
        { key: 'date', label: 'DATE' },
        { key: 'position', label: 'POSITION' },
        { key: 'share', label: 'IMPRESSION SHARE' },
        { key: 'price', label: 'PRICE' },
        { key: 'more', label: '' },
      ],
      reportBrandFields: [
        { key: 'brand', label: 'BRAND', sortable: true },
        { key: 'position', label: 'POSITION', sortable: true },
        { key: 'share', label: 'IMPRESSION SHARE', sortable: true },
        { key: 'share_relative', label: 'IMPRESSION SHARE RELATIVE', sortable: true },
        { key: 'price', label: 'PRICE', sortable: true },
        { key: 'count', label: 'COUNT', sortable: true },
      ],
      reportInfoMoreFields: [
        { key: 'brand', label: 'BRAND' },
        { key: 'price', label: 'PRICE' },
      ],
      typeFilter: '',
      typeOptions: [
        { label: 'SEA', value: 'SEA' },
        { label: 'PLA', value: 'PLA' },
      ],
      searchQuery: null,
      keywordsOptions: [],
      resultItems: [],
      name: '',
      date: null,
      dateDefault: [new Date().fp_incr(-30), new Date()],
      modalTitle: '',
      reportInfoItems: [],
      reportBrandItems: [],
      showModal: 'modal-info',
    }
  },
  setup() {
    /* eslint-disable global-require */
    const noImage = require('@/assets/images/elements/no-image.jpg')
    const REPORT_APP_STORE_MODULE_NAME = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) {
      store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
      }
    })
    return {
      noImage,
    }
  },
  computed: {
    keywordsOptionsFilter() {
      return this.keywordsOptions.filter(keywords => keywords.value === this.typeFilter)
    },
  },
  created() {
    store.dispatch('app-report/getKeywords')
      .then(response => {
        const data1 = response.data
        this.keywordsOptions = data1.map(a => ({
          label: a.keywords, value: a.type,
        }))
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    searchResult() {
      if (this.searchQuery === '' || this.searchQuery === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Keywords',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (this.dateDefault === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Date',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        store.dispatch('app-report/fetchReport', {
          type: this.typeFilter,
          search: this.searchQuery.label,
          date: this.dateDefault,
        })
          .then(response => {
            if (response.data) {
              this.name = response.data.name
              this.resultItems = response.data
            } else {
              this.name = null
              this.resultItems = null
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    clickMore(date) {
      this.showModal = 'modal-brand'
      this.modalTitle = date
      store.dispatch('app-report/fetchReportByBrand', {
        date: [date],
        type: this.typeFilter,
        search: this.searchQuery.label,
      })
        .then(response => {
          this.reportBrandItems = []
          Object.keys(response.data.brands).forEach(key => {
            this.reportBrandItems.push({
              brand: key,
              position: response.data.brands[key].position,
              count: response.data.brands[key].count,
              price: response.data.brands[key].price,
              share: response.data.brands[key].share,
              share_relative: response.data.brands[key].share_relative,
            })
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clickRow(record) {
      this.showModal = 'modal-info'
      this.modalTitle = record.date
      store.dispatch('app-report/fetchReportInfo', {
        date: record.date,
        type: this.typeFilter,
        search: this.searchQuery.label,
      })
        .then(response => {
          this.reportInfoItems = response.data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
